import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import sanitize from "sanitize-html";

import { Button } from "@material-ui/core";

import { getNotificationByUuid } from "../../../redux/actions/admin/notificationActions";

export default function EmailDetail() {
  const { t } = useTranslation("common");
  const notification = useSelector((state) => state.notificationReducers);
  const dispatch = useDispatch();
  let { uuid } = useParams();

  useEffect(() => {
    if (!notification.record_loaded) {
      dispatch(getNotificationByUuid(uuid));
    }
  }, [notification.record_loaded, dispatch, uuid]);

  useEffect(() => {
    return () => {
      dispatch({ type: "RELOAD_RECORD" });
    };
  }, [dispatch]);

  return (
    <>
      {notification.record_loaded ? (
        <>
          <div className="mt-5">
            <Link
              to="/vacancies/admin/notifications"
              onClick={() => dispatch({ type: "RELOAD_RECORD" })}
            >
              <Button className="focus:outline-none" variant="outlined">
                <i className="fa fa-arrow-left mr-2"></i> {t("personal.back")}
              </Button>
            </Link>
          </div>
          <div className="my-8">
            <div className="flex flex-col items-center justify-center bg-red-500 text-white">
              <div className="pt-9 pb-8">
                <div className="w-full flex justify-center">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/img/logoemail.png`}
                    alt=""
                    width={200}
                    className="fill-current"
                  />
                </div>
                <div className="p-5 pt-10 w-full">
                  <p className="ml-3 mb-3 text-2xl font-semibold">
                    {`${t("notification.greeting")} ${t(
                      "notification.applicant_name"
                    )}`}
                    ,
                  </p>
                  <p className="w-4/5">{t("notification.description")}</p>
                </div>
              </div>
            </div>
            <div className="bg-white">
              <div
                className="md:w-2/5 mx-auto"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <div className="w-full pt-12 px-10 pb-3">
                  <h3 className="text-2xl font-semibold text-center mb-8">
                    {`${notification.record.title} ${t(
                      "notification.vacant"
                    )}: ${t("notification.job_name")}`}
                  </h3>
                  <div className="text-gray-400 font-light text-sm leading-7" dangerouslySetInnerHTML={{ __html: sanitize(notification.record.message) }}>
                  </div>
                </div>
                <div
                  className="w-full py-7 px-10"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <div className="border-t border-b border-red-500 py-5 text-center">
                    <a
                      href="https://www.bharat-techpro.com"
                      className="text-sm font-semibold text-blue-700 underline"
                    >
                      www.bharat-techpro.com
                    </a>
                  </div>
                  <p
                    className="text-gray-500 font-light leading-4 mt-3"
                    style={{ fontSize: "10px" }}
                  >
                    {t("notification.noreply")}
                  </p>
                  <div className="flex justify-center mt-8">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/img/faviconnegro.png`}
                      alt=""
                      width={80}
                      className="fill-current"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="justify-center">
          <div className="text-center mt-56 p-4 overflow-x-auto">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        </div>
      )}
    </>
  );
}
