import React, { useState, useEffect } from "react";
import {
  userCheckEmail,
  registerVacancies,
} from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import imgRegister from "../../views/assets/img/close-up-man-writing-code-on-the-laptop.jpg";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { SingleError } from "../common";

export default function Register(props) {
  const { initialEffect } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducers);
  const { t } = useTranslation("common");
  const [isFileBigger, setIsFileBigger] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      lastname: "",
      email: "",
      password: "",
      repeat_password: "",
    },
  });

  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [hasErrorEmail, setHasEmailError] = useState(false);

  const onSubmitData = async (data) => {
    const { password, repeat_password, email, file } = data;
    const fileValid = file?.size / 1024 / 1024 < 1;

    if (password !== repeat_password) {
      setHasPasswordError(true);
      return;
    }

    if (file && !fileValid) {
      setIsFileBigger(true);
      return;
    }
    //se toma el valor del correo
    const existingEmail = await dispatch(userCheckEmail({ email: email }));

    if (existingEmail) {
      //está duplicado el correo
      setHasEmailError(true);
      return;
    }

    const formData = new FormData();
    formData.append("vacancies", true);
    Object.entries(data).map(([key, value]) => formData.append(key, value));

    await dispatch(registerVacancies(formData));
  };

  useEffect(() => {
    register("file", {
      required: t("portfolio.error.file_required"),
    });
  }, [register, t]);

  return (
    <>
      <div
        className={`flex items-center px-6 pb-8 xl:pt-24 xl:pb-36 pt-4 bg-gray-200 animate__animated animate__fadeIn animate__faster ${initialEffect}`}
      >
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-gray-200 rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-32 md:h-auto md:w-1/2">
              <img
                aria-hidden="true"
                className="object-cover w-full h-full dark:hidden"
                src={imgRegister}
                alt="Office"
              />
              <img
                aria-hidden="true"
                className="hidden object-cover w-full h-full dark:block"
                src=""
                alt="Office"
              />
            </div>

            <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold font-poppins text-gray-700 dark:text-gray-200">
                  <span className="block xl:inline">
                    {t("register.cv_title")}{" "}
                  </span>
                </h1>
                <SingleError
                  status={isFileBigger}
                  styleParent="mb-5 w-full mx-auto"
                  errors={t("portfolio.error.cv_limit")}
                  closer={() => setIsFileBigger(false)}
                />
                <SingleError
                  status={hasPasswordError}
                  styleParent="mb-5 w-full mx-auto"
                  errors={t("personal.error.password_not_match")}
                  closer={() => setHasPasswordError(false)}
                />
                <SingleError
                  status={hasErrorEmail}
                  styleParent="mb-5 w-full mx-auto"
                  errors={t("personal.error.email_duplicate")}
                  closer={() => setHasEmailError(false)}
                />

                <form onSubmit={handleSubmit(onSubmitData)}>
                  <div className="grid grid-cols-2 gap-5">
                    <label className="block text-sm mb-4 font-poppins">
                      <span className="text-gray-700 font-poppins dark:text-gray-400 mb-5">
                        {t("personal.first_name")}
                      </span>
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t("personal.error.name_required"),
                          },
                          pattern: {
                            value:
                              /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                            message: t("personal.error.name_pattern"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            type="text"
                            id="name"
                            name="name"
                            variant="outlined"
                            className="block w-full font-poppins mt-1 border-0 placeholder-gray-400 text-gray-700 bg-white rounded text-sm  focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent"
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({ message }) => (
                          <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                            <svg
                              className="inline flex-shrink-0 mr-3 w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span>{message}</span>
                          </div>
                        )}
                      />
                    </label>
                    <label className="block text-sm mb-4 font-poppins">
                      <span className="text-gray-700 font-poppins dark:text-gray-400 mb-5">
                        {t("personal.lastname")}
                      </span>
                      <Controller
                        name="lastname"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t("personal.error.last_required"),
                          },
                          pattern: {
                            value:
                              /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
                            message: t("personal.error.lastname_pattern"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            type="text"
                            id="lastname"
                            name="lastname"
                            variant="outlined"
                            className="block w-full font-poppins mt-1 border-0 placeholder-gray-400 text-gray-700 bg-white rounded text-sm  focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent"
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="lastname"
                        render={({ message }) => (
                          <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                            <svg
                              className="inline flex-shrink-0 mr-3 w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span>{message}</span>
                          </div>
                        )}
                      />
                    </label>
                  </div>
                  <label className="block font-poppins text-sm mb-4">
                    <span className="text-gray-700 font-poppins dark:text-gray-400">
                      {t("personal.email")}
                    </span>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t("personal.error.email_required"),
                        },
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,7}$/,
                          message: t("personal.error.email"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          type="email"
                          id="email"
                          name="email"
                          variant="outlined"
                          className="block w-full font-poppins mt-1 border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm  focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent"
                          {...field}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                          <svg
                            className="inline flex-shrink-0 mr-3 w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>{message}</span>
                        </div>
                      )}
                    />
                  </label>
                  <label className="block font-poppins text-sm mb-4">
                    <span className="text-gray-700 font-poppins dark:text-gray-400">
                      {t("personal.password")}
                    </span>
                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t("personal.error.password_required"),
                        },
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.$!%*?&#/¿¡+-])[A-Za-z\d@.$!%*?&#/¿¡+-]{8,}$/,
                          message: t("personal.error.password_pattern"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          type="password"
                          id="password"
                          name="password"
                          variant="outlined"
                          className="block w-full font-poppins mt-1 border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm  focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent"
                          {...field}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="password"
                      render={({ message }) => (
                        <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-2">
                          <svg
                            className="inline flex-shrink-0 mr-3 w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>{message}</span>
                        </div>
                      )}
                    />
                  </label>
                  <label className="block font-poppins text-sm mb-4">
                    <span className="text-gray-700 font-poppins dark:text-gray-400">
                      {t("personal.repeat_password")}
                    </span>
                    <Controller
                      name="repeat_password"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t("personal.error.password_required"),
                        },
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.$!%*?&#/¿¡+-])[A-Za-z\d@.$!%*?&#/¿¡+-]{8,}$/,
                          message: t("personal.error.password_pattern"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          type="password"
                          id="repeat_password"
                          name="repeat_password"
                          variant="outlined"
                          className="block w-full font-poppins mt-1 border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm  focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent"
                          {...field}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="repeat_password"
                      render={({ message }) => (
                        <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                          <svg
                            className="inline flex-shrink-0 mr-3 w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span>{message}</span>
                        </div>
                      )}
                    />
                  </label>
                  <label
                  htmlFor="file"
                  className="block font-poppins w-full px-2 py-2 my-8 text-sm font-medium leading-5 text-center text-gray-700 transition-colors duration-150 bg-gray-300 hover:bg-gray-700 rounded hover:text-white cursor-pointer border border-transparent  active:bg-purple-600  focus:outline-none focus:shadow-outline-red"
                >
                  <i className=" mr-2 fa fa-thumbtack"></i> {t("portfolio.cv")}
                </label>
                  <div className="hidden">
                    <TextField
                      type="file"
                      id="file"
                      name="file"
                      label={t("portfolio.file")}
                      variant="outlined"
                      className="hidden"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        accept: ".png,.jpg,.jpeg,.pdf,.docx,.doc,.odt,.odg",
                      }}
                      onChange={(event) =>
                        setValue("file", event.target?.files[0])
                      }
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="file"
                    render={({ message }) => (
                      <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                        <svg
                          className="inline flex-shrink-0 mr-3 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>{message}</span>
                      </div>
                    )}
                  />
                  <button
                    className="block w-full font-poppins px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-red"
                    type="submit"
                  >
                    {t("personal.register")}
                  </button>
                  <Link
                    to="/"
                    className="block px-4 py-2 mt-10 text-sm font-poppins font-medium leading-5 text-center text-gray-700 hover:text-white transition-colors duration-150 border border-gray-300 rounded-lg hover:bg-gray-800 hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
                    disabled={user.button.status}
                    type="button"
                  >
                    <i className="fa fa-arrow-left mr-1"></i>{" "}
                    {t("personal.back")}
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
