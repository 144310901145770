/**
 * javascript comment
 * @Author: Publio Quintero
 * @Date: 2021-04-16 08:53:13
 * @Desc: @env indica si se va usar el servidor local o el servidor real en producción. 1 es para servidor, 0 para local
 */

const baseUrl = process.env.REACT_APP_BASE_URL;
const type_token = localStorage.getItem("token_type");
const access_token = localStorage.getItem("access_token");

export const api = {
  url: baseUrl + process.env.REACT_APP_API_URL,
  type: type_token,
  token: access_token,
};

/**
 * javascript comment
 * @Author: Publio Quintero
 * @Date: 2021-04-16 08:54:22
 * @Desc: 	? Clave secreta para el servidor real
 * 			: Clave secreta servidor local
 */

export const oauth = {
  url: baseUrl + process.env.REACT_APP_TOKEN_URL,
  secret: process.env.REACT_APP_CLIENT_SECRET,
  id: process.env.REACT_APP_CLIENT_ID,
  type: "password",
  scope: "*",
};

export const verifyLogin = () => {
  let loggedIn = false;
  if (type_token !== null && access_token !== null) {
    loggedIn = true;
  }
  return loggedIn;
};

// Error Props
// import { handleErrorMessage, handleErrorInput } from '../../utils/functions'
// error={handleErrorInput(reducer, "algo")}
// helperText={handleErrorMessage(reducer, "algo")}

export const handleErrorMessage = (reducer, name) => {
  if (reducer.error && reducer.error_message[name])
    return reducer.error_message[name][0];
};

export const handleErrorInput = (reducer, name) => {
  return reducer.error && reducer.error_message[name] ? true : false;
};

export const setLang = () => {
  const langItem = localStorage.getItem("lang");
  if (!langItem) {
    localStorage.setItem("lang", "en");
  }
};

export const errorInterceptor = (axiosInstance, hiddenLoading, useDispatch) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      useDispatch(hiddenLoading());
      return response;
    },
    (error) => {
      useDispatch(hiddenLoading());
      console.group("Error");
      console.log(error);
      console.groupEnd();
    }
  );
};

export const updateHeaderInterceptor = (
  axiosInstance,
  hiddenLoading,
  showLoading,
  useDispatch
) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      useDispatch(showLoading());
      return config;
    },
    (error) => {
      useDispatch(hiddenLoading());
    }
  );
};

export const changeLang = (lang) => {
  localStorage.removeItem("lang");
  localStorage.setItem("lang", lang);
};

export const isUserIncomplete = (object) => {
  const properties = Object.keys(object).length - 1;

  const valuesInObject = Object.entries(object).reduce(
    (settedValues, [name, value]) =>
      value && name !== "email_verified_at" ? settedValues + 1 : settedValues,
    0
  );

  return valuesInObject < properties ? true : false;
};

/*
import {useTranslation} from "react-i18next";
const { t } = useTranslation('common');
{t('skills.algo')}
*/
