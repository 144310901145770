import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getdataskill,
  deleteRecordsSkills,
} from "../../../redux/actions/admin/usersActions";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../../redux/actions/admin/usersActions";
export default function UserSkills(props) {
  const { records } = props;
  const location = useLocation();
  const { t } = useTranslation("common");

  const deleteSkill = async (idSkill, uuidUser) => {
    //se llama la función que invoca el endpoint que elimina de las tablas user_skill y user_test
    await dispatch2(deleteRecordsSkills(idSkill, uuidUser));
    await dispatch2(getUserProfile(uuidUser));
  };

  const resulskill = useSelector((state) => state.usersReducers);
  const dispatch2 = useDispatch();
  useEffect(() => {
    if (!resulskill.skills_loaded) {
      dispatch2(getdataskill(records[0].uuid));
    }
  }, [dispatch2, records, resulskill.skills_loaded]);

  return (
    <>
      {resulskill.skills_loaded && (
        <table className="w-full userInfoTable font-poppins text-sm">
          <thead>
            <tr>
              <th>{t("personal.name")}</th>
              <th>{t("menu_profile.experience")}</th>
              <th>{t("userSkills.typeQuestion")}</th>
              <th>{t("userSkills.information")}</th>
              <th>{t("userSkills.result")}</th>
              <th>{t("skills.status_test")}</th>
              <th>{t("skills.habilityTest")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(records).map(([key, item]) => (
              <tr key={key}>
                <td className="w-2/12">{item.skill.name}</td>
                <td className="w-2/12">
                  {/* se verifica si el timpo de experiencia está vació.. Se pasa así para los skill bloqueados                             */}
                  {item.experience_time !== "" && (
                    <>
                      {item.experience_time.split(" ")[0]}{" "}
                      {t("skills." + item.experience_time.split(" ")[1])}
                    </>
                  )}
                </td>
                <td className="w-2/12">
                  {resulskill !== undefined &&
                    resulskill.resulskill !== undefined &&
                    resulskill.resulskill[item.uuid] !== undefined &&
                    resulskill.resulskill[item.uuid].length > 0 && (
                      <>
                        <div>
                          <div>
                            <span>
                              {" "}
                              {t("userSkills.text")} :{" "}
                              {Number(
                                resulskill.resulskill[item.uuid][0].text
                              ) !== undefined
                                ? Number(
                                    resulskill.resulskill[item.uuid][0].text
                                  )
                                : 0}
                            </span>{" "}
                          </div>
                          <div>
                            <span>
                              {" "}
                              {t("userSkills.select")} :{" "}
                              {Number(
                                resulskill.resulskill[item.uuid][0].selection
                              ) !== undefined
                                ? Number(
                                    resulskill.resulskill[item.uuid][0]
                                      .selection
                                  )
                                : 0}
                            </span>{" "}
                          </div>
                        </div>
                      </>
                    )}
                </td>
                {/* cantidad de respuestas correctas e incorrectas       */}
                <td className="w-2/12">
                  {resulskill !== undefined &&
                    resulskill.resulskill !== undefined &&
                    resulskill.resulskill[item.uuid] !== undefined &&
                    resulskill.resulskill[item.uuid].length > 0 && (
                      <div>
                        <div>
                          <i className="fa fa-check text-green-500 ml-2"></i>{" "}
                          <span>
                            {" "}
                            {Number(resulskill.resulskill[item.uuid][0].correct)
                              ? Number(
                                  resulskill.resulskill[item.uuid][0].correct
                                )
                              : 0}{" "}
                            /{" "}
                            {Number(resulskill.resulskill[item.uuid][0].correct)
                              ? (
                                  (resulskill.resulskill[item.uuid][0].correct *
                                    100) /
                                  (Number(
                                    resulskill.resulskill[item.uuid][0].correct
                                  ) +
                                    Number(
                                      resulskill.resulskill[item.uuid][0]
                                        .incorrect
                                    ) +
                                    Number(
                                      resulskill.resulskill[item.uuid][0]
                                        .unevalued
                                    ))
                                ).toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div>
                          <i className="fa fa-times text-red-500 ml-2"></i>
                          {"  "}
                          <span>
                            {" "}
                            {Number(
                              resulskill.resulskill[item.uuid][0].incorrect
                            )
                              ? Number(
                                  resulskill.resulskill[item.uuid][0].incorrect
                                )
                              : 0}{" "}
                            /{" "}
                            {Number(
                              resulskill.resulskill[item.uuid][0].incorrect
                            )
                              ? (
                                  (resulskill.resulskill[item.uuid][0]
                                    .incorrect *
                                    100) /
                                  (Number(
                                    resulskill.resulskill[item.uuid][0].correct
                                  ) +
                                    Number(
                                      resulskill.resulskill[item.uuid][0]
                                        .incorrect
                                    ) +
                                    Number(
                                      resulskill.resulskill[item.uuid][0]
                                        .unevalued
                                    ))
                                ).toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div>
                          <i className="fa fa-edit text-yellow-500 ml-2"></i>{" "}
                          <span>
                            {Number(
                              resulskill.resulskill[item.uuid][0].unevalued
                            )
                              ? Number(
                                  resulskill.resulskill[item.uuid][0].unevalued
                                )
                              : 0}{" "}
                            /{" "}
                            {Number(
                              resulskill.resulskill[item.uuid][0].unevalued
                            )
                              ? (
                                  (resulskill.resulskill[item.uuid][0]
                                    .unevalued *
                                    100) /
                                  (Number(
                                    resulskill.resulskill[item.uuid][0].correct
                                  ) +
                                    Number(
                                      resulskill.resulskill[item.uuid][0]
                                        .incorrect
                                    ) +
                                    Number(
                                      resulskill.resulskill[item.uuid][0]
                                        .unevalued
                                    ))
                                ).toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                    )}
                </td>
                {/* resultado de la prueba */}
                <td className="w-2/12">
                  {resulskill !== undefined &&
                    resulskill.resulskill !== undefined &&
                    resulskill.resulskill[item.uuid] !== undefined &&
                    resulskill.resulskill[item.uuid].length > 0 && (
                      <div>
                        <div>
                          {Number(resulskill.resulskill[item.uuid][0].points) <=
                            200 &&
                          Number(resulskill.resulskill[item.uuid][0].points) >=
                            81 ? (
                            <span className="font-semibold underline text-green-500">
                              {t("userSkills.sobresal")}
                            </span>
                          ) : Number(
                              resulskill.resulskill[item.uuid][0].points
                            ) <= 80 &&
                            Number(
                              resulskill.resulskill[item.uuid][0].points
                            ) >= 61 ? (
                            <span className="font-semibold underline text-blue-500">
                              {t("userSkills.excellent")}
                            </span>
                          ) : Number(
                              resulskill.resulskill[item.uuid][0].points
                            ) <= 60 &&
                            Number(
                              resulskill.resulskill[item.uuid][0].points
                            ) >= 41 ? (
                            <span className="font-semibold underline text-yellow-500">
                              {t("userSkills.regular")}
                            </span>
                          ) : Number(
                              resulskill.resulskill[item.uuid][0].points
                            ) <= 40 &&
                            Number(
                              resulskill.resulskill[item.uuid][0].points
                            ) >= 21 ? (
                            <span className="font-semibold underline text-yellow-500">
                              {t("userSkills.deficent")}
                            </span>
                          ) : (
                            <span className="font-semibold underline text-red-500">
                              {t("userSkills.bad")}
                            </span>
                          )}
                        </div>
                        <div>
                          <span className="font-semibold">
                            {t("userSkills.points")}{" "}
                            {resulskill.resulskill[item.uuid][0].points}
                          </span>{" "}
                        </div>
                      </div>
                    )}
                </td>
                {/* estado de la prueba. En espera o completa */}
                <td className="w-2/12">
                  {item.status_test > 0 ? (
                    <div>
                      <span className="text-green-600 font-semibold">
                        {t("userSkills.completed")}
                      </span>{" "}
                      <Link
                        className="text-blue-600 focus:outline-none"
                        to={`/admin/users/result/${item.uuid}`}
                        onClick={() =>
                          localStorage.setItem("back_url", location.pathname)
                        }
                        target="_blank"
                      >
                        {t("userSkills.seeResults")}
                      </Link>
                    </div>
                  ) : item.experience_time === "" ? (
                    <>
                      <span className="text-green-500">
                        {t("skills.blocked")}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-red-600">
                        {t("userSkills.wait")}
                      </span>
                    </>
                  )}
                </td>
                {/* intentos de la prueba */}
                <td className="w-2/12">
                  {item.attemps === 2 && (
                    <>
                      <button
                        //   onClick={onLogout}
                        onClick={() => deleteSkill(item.skill.id, item.uuid)}
                        className=" bg-green-500 text-white active:bg-emerald-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:bg-black-700 hover:shadow-md outline-none focus:outline-none mr-1 mb-1"
                      >
                        {t("skills.habilityTest")}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
