import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import {
  getUsers,
  filterUsers,
} from "../../../redux/actions/admin/usersActions";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getSkills } from "../../../redux/actions/admin/skillActions";
import { getJobs } from "../../../redux/actions/admin/jobActions";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

// components;
import { Pagination, InputSearch, TableGrid } from "../../common";
import ModalDeleteUser from "../../modals/ModalDeleteUser";
import RoleModal from "./RoleModal";

export default function UsersIndex() {
  const { t, i18n } = useTranslation("common");

  const columns = useMemo(
    () => [
      {
        Header: () => t("personal.name"),
        accessor: "fullname",
      },
      {
        Header: () => t("personal.email"),
        accessor: "email",
      },
      {
        Header: () => t("personal.personal_phone"),
        accessor: "personal_phone",
      },
      {
        Header: () => t("userSkills.accountCreate"),
        accessor: "created_at",
        Cell: ({ value: created_at }) => {
          const localeFormat = i18n.language === "en" ? "en-US" : "es-CO";
          return (
            created_at && new Date(created_at).toLocaleDateString(localeFormat)
          );
        },
      },
      {
        Header: () => t("userSkills.additional"),
        accessor: "name",
        Cell: ({ row: { original: item } }) => (
          <>
            {item?.personal_phone && (
              <Tooltip title={t("personal.personalInfo")} arrow>
                <i className="fas fa-address-book text-green-500 mx-1  mt-1"></i>
              </Tooltip>
            )}
            {item?.file && (
              // se agrega el link para descargar el cv
              <Tooltip title={t("portfolio.attachCv")} arrow>
                <a
                  href={`${process.env.REACT_APP_BASE_URL}${item.file}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-file-import text-blue-500  mt-1 mx-1"></i>
                </a>
              </Tooltip>
            )}
            {item?.hired_strut === "Y" && (
              <Tooltip title={t("list.hired")} arrow>
                <i className="fas fa-business-time text-gray-500 mt-1 mx-1"></i>
              </Tooltip>
            )}
            {item?.avalaible_strut === "Y" && (
              <span className="inline-block mx-1 px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-red-100">
                {t("list.avalaible")}
              </span>
            )}
          </>
        ),
      },
      {
        Header: () => t("role.role_type"),
        accessor: "role_id,uuid",
        Cell: ({
          row: {
            original: { role_id, uuid },
          },
        }) => (
          <button
            className="my-1 h-10 border-2 border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
            onClick={() => {
              setRoleData({ role_id, uuid });
              handleOpenModal();
            }}
          >
            <i className="fa fa-wrench"></i>
          </button>
        ),
      },
      {
        Header: () => t("userSkills.details"),
        accessor: "uuid",
        Cell: ({
          row: {
            original: { uuid },
          },
        }) => (
          <Link
            className="btn_options my-1 border-2 border-gray-500  hover:bg-gray-200 text-gray-500 hover:text-gray-600 font-bold mx-1 px-2 pb-0.5 rounded-full"
            to={`/admin/users/profile/${uuid}`} target="_blank"
          >
            <i className="w-2 h-2 text-xs fa fa-arrow-right"></i>
          </Link>
        ),
      },
      {
        Header: () => t("users.delete_user"),
        accessor: "role_id",
        Cell: ({
          row: {
            original: { uuid },
          },
        }) => (
          <div className="flex justify-start text-sm">
            <ModalDeleteUser uuid={uuid}></ModalDeleteUser>
          </div>
        ),
      },
    ],
    [t, i18n.language]
  );

  const users = useSelector((state) => state.usersReducers);
  const [filterRecords, setFilterRecords] = useState(users.records);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filterRecords,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const dispatch = useDispatch();
  const skill = useSelector((state) => state.skillsReducers);
  const jobs = useSelector((state) => state.jobsReducers);
  const [showFilters, setShowFilters] = useState(false);
  const [roleData, setRoleData] = useState({});
  const [openRoleModal, setOpenRoleModal] = useState(false);

  const typeUsers = [
    { type: t("clients.administrator"), id: 1 },
    { type: t("vacancies.vacancies"), id: 2 },
    { type: t("clients.hired"), id: 3 },
  ];

  useEffect(() => {
    if (!users.loaded) {
      dispatch(getUsers());
    } else {
      setFilterRecords(users.records);
    }
  }, [dispatch, users.loaded, users.records]);

  useEffect(() => {
    if (!skill.loaded) {
      dispatch(getSkills());
    }
  }, [dispatch, skill.loaded]);

  useEffect(() => {
    if (!jobs.loaded) {
      dispatch(getJobs(i18n.language));
    }
  }, [dispatch, jobs.loaded, i18n.language]);

  useEffect(() => {
    if (users.search !== undefined && users.loaded) {
      setFilterRecords([...users.search]);
    }
  }, [users]);

  const [dataFilter, setDataFilter] = useState({
    job: 0,
    skill: 0,
    userType: 0,
  });

  const toggleFilters = () => {
    dataFilter.skill = 0;
    dataFilter.userType = 0;
    dataFilter.job = 0;
    setShowFilters(!showFilters);
  };

  const filterUsersSubmit = (e) => {
    e.preventDefault();
    if (dataFilter.skill || dataFilter.userType || dataFilter.job) {
      dispatch(filterUsers(dataFilter));
    }

    setShowFilters(false);
  };

  const handleChange = (name, value) => {
    setDataFilter({
      ...dataFilter,
      [name]: value,
    });
  };

  const handleOpenModal = () => setOpenRoleModal(true);
  const handleCloseModal = () => setOpenRoleModal(false);

  const resetTable = () => {
    setFilterRecords(users.records);
  };

  return (
    <div className="animate__animated animate__fadeIn animate__faster">
      <div className="w-11/12 mx-auto">
        <>
          <section className="text-gray-600 body-font">
            <div className="container px-5 pt-20 pb-4 mx-auto flex items-center md:flex-row flex-col">
              <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
                <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-2 bg-blue-50 rounded px-2 py-2 w-52">
                  <i className="mr-4 fas fa-users"></i>
                  {t("userSkills.listspan")}
                </h2>
                <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
                  {t("vacancies.listuser")}
                </h1>
              </div>
              <div className="flex flex-row md:flex-row md:ml-auto md:mr-0 mx-auto items-start flex-shrink-0 space-x-4">
                <Link to="/admin/users/list" className="mr-2">
                  <button className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none">
                    <i className="fas fa-print"></i>
                    <span className="ml-4 flex items-start flex-col leading-none">
                      <span className="text-xs text-gray-600 mb-1">
                        {t("userSkills.print")}
                      </span>
                      <span className="title-font font-medium">
                        {t("userSkills.listspan")}
                      </span>
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </section>
          <div className="flex items-center">
            <div>
              <InputSearch
                setValue={setValue}
                onChange={onChangeInputSearch}
                value={value}
              />
            </div>
            <div className="relative">
              <div className="flex items-center mt-2">
                <div
                  className="flex ml-8 cursor-pointer"
                  onClick={toggleFilters}
                >
                  <svg
                    className="h-6 w-6 mr-2 text-gray-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                  </svg>
                  <span>{t("tables.advancedSearch")}</span>
                </div>
                <button
                  className="ml-6 bg-gray-100 hover:bg-gray-300 py-2 px-5 rounded-lg font-medium"
                  onClick={resetTable}
                >
                  {t("tables.all")}
                </button>
              </div>
              {showFilters && (
                <div className="bg-white p-5 flex flex-col absolute left-5 top-14 border rounded-lg shadow-md">
                  <form onSubmit={filterUsersSubmit}>
                    <Autocomplete
                      id="vacant"
                      className="h-1/4"
                      options={jobs.data}
                      getOptionLabel={(option) => option.name_job}
                      style={{ width: 380 }}
                      onChange={(event, value) =>
                        value && handleChange("job", value.id)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("vacancies.selectFilter")}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                    <Autocomplete
                      id="skill"
                      className="mt-4"
                      options={skill.records}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 380 }}
                      onChange={(event, value) =>
                        value && handleChange("skill", value.id)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("skills.selectFilter")}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                    <Autocomplete
                      id="type-user"
                      className="mt-4"
                      options={typeUsers}
                      getOptionLabel={(option) => option.type}
                      style={{ width: 380 }}
                      onChange={(event, value) =>
                        value && handleChange("userType", value.id)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("clients.selectFilter")}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="cursor-pointer mt-3 bg-black text-white px-6 py-2 rounded-lg"
                      >
                        {t("tables.filter")}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>

          {!users.loaded || !filterRecords?.length ? (
            <div className="my-24 justify-content-center">
              <div className="text-center my-5 p-4 overflow-x-auto">
                <i className="fa fa-spin fa-spinner fa-2x"></i>
              </div>
            </div>
          ) : (
            <>
              <TableGrid
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                loaded={users.loaded}
                getTableBodyProps={getTableBodyProps}
                page={page}
                prepareRow={prepareRow}
              />
              <Pagination
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </>
          )}

          {openRoleModal && roleData && (
            <RoleModal
              open={openRoleModal}
              handleClose={handleCloseModal}
              roleData={roleData}
            />
          )}
        </>
      </div>
    </div>
  );
}
