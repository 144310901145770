import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../admin/footer";

export default function HomeJobs() {
    const { t } = useTranslation('common');
    const [find, setFind] = useState({
        name_job: [],
        ubication: [],
    });

    const handleChange = (text) => {
        setFind({
            ...find,
            [text.target.name]: text.target.value,
        });
    };

    return (
        <>
            <main>
                <section className="text-gray-600 body-font">
                    <div className="container px-10 lg:px-40 pt-20 pb-14 lg:pb-28 mx-auto">
                        <div className="text-center max-w-xl mb-10 lg:my-20 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                                <h2 className="max-w mb-4 font-poppins text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto">
                                    <span className="relative inline-block">
                                        <svg viewBox="0 0 47 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                                            <defs>
                                                <pattern id="07690130-d013-42bc-83f4-90de7ac68f76" x="0" y="0" width=".135" height=".30">
                                                    <circle cx="1" cy="1" r=".7"></circle>
                                                </pattern>
                                            </defs>
                                            <rect fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)" width="50" height="24"></rect>
                                        </svg>
                                        <span className="relative"> {t('vacancies.startCareer')} </span>
                                    </span>
                                </h2>
                                <p className="text-base font-poppins text-gray-700 md:text-lg">
                                    {t('vacancies.findOut')}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center lg:w-5/6 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0">
                            <div className="relative flex-grow w-full">
                                <label htmlFor="name_job" className="leading-7 text-sm fonts-poppins text-gray-600 font-poppins"> {t('vacancies.whatJob')} </label>
                                <div className="relative text-gray-500 focus-within:text-gray-400">
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </span>
                                    <input
                                        type="text"
                                        id="name_job"
                                        name="name_job"
                                        onChange={handleChange}
                                        placeholder={t('vacancies.jobposition')}
                                        className="font-poppins w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-red-500 focus:bg-transparent focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div className="relative flex-grow w-full">
                                <label htmlFor="ubication" className="leading-7 text-sm fonts-poppins text-gray-600 font-poppins"> {t('vacancies.whereJob')} </label>
                                <div className="relative text-gray-500 focus-within:text-gray-400">
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                                            <i className="fas fa-street-view"></i>
                                        </button>
                                    </span>
                                    <input
                                        type="text"
                                        id="ubication"
                                        name="ubication"
                                        onChange={handleChange}
                                        placeholder={t('vacancies.city')}
                                        className="font-poppins w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-700 focus:bg-transparent focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div>
                                <Link to={`/jobs?name_job=${find.name_job}&ubication=${find.ubication}`} className="font-poppins bg-gradient-to-r from-gray-700 to-blue-700 hover:from-blue-700 hover:to-blue-700 text-white font-semibold inline-flex items-center text-xs lg:text-base py-2 px-10 mt-8 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                     {t('vacancies.find')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <hr />
                </section>
                <section className="text-gray-600 body-font ">
                    <main className=" flex justify-center pb-10 bg-gray-50">
                        <div className="flex flex-col justify-center text-center w-4/4">
                            <div className="flex-col pt-8 pb-10 ">
                                <p className="font-poppins mx-20 text-base text-gray-700 md:text-lg">
                                    {t('vacancies.partTeam')}
                                </p>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-4/5 m-auto mt-20 mb-10">
                                    <Link to="/register/" className="border-t-4 mx-4 border-gray-400 hover:border-blue-600 text-gray-400 hover:text-blue-600 pt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                        <p className="uppercase font-bold"> {t('vacancies.create_account')}</p>

                                        <p className="font-normal text-gray-500 text-sm mt-4">{t('vacancies.step_one')}</p>
                                    </Link>
                                    <Link to="/profile/skills" className="border-t-4 mx-4 border-gray-500 hover:border-blue-600 text-gray-500 hover:text-blue-600 pt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                        <p className="uppercase font-bold">{t('vacancies.complete_account')}</p>

                                        <p className="font-normal text-sm text-gray-500 mt-4">{t('vacancies.step_two')}</p>
                                    </Link>
                                    <Link to="/jobs" className="border-t-4 mx-4 border-gray-600 hover:border-blue-600 text-gray-600 hover:text-blue-600 pt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                        <p className="uppercase font-bold">{t('vacancies.apply_job')}</p>

                                        <p className="font-normal text-gray-500 text-sm mt-4">{t('vacancies.step_three')}</p>
                                    </Link>
                                </div> 
                               {/*  <p className="font-poppins mx-20 text-base text-gray-700 md:text-lg">
                                    {t('vacancies.partTeam')}
                                </p> */}
                                <Link to="/register/" className="animate-bounce transform  translate-y-0 transition duration-3500 ease-in-out font-poppins border-2 border-gray-800 hover:bg-gray-800 text-gray-800 hover:text-white inline-flex items-center text-xs lg:text-base font-bold py-2 px-6 mx-2 mt-8 mb-2 rounded-lg">
                                    {t('vacancies.calltoaction')}
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </Link>    

                                <hr className="my-6 text-gray-800" />
                                <span className="mt-8 font-poppins text-gray-800 text-xs">{t('vacancies.time')}</span>
                                <h6 className="mb-4 mx-10 font-semibold leading-5 text-sm text-blue-500 mt-4">
                                    <span className="font-poppins text-gray-800 font-normal">{t('vacancies.applyto_job')}</span>
                                    <Link to="/register/vacancies/" className="font-poppins text-blue-500">
                                        {t('vacancies.publishCV')}
                                    </Link>
                                </h6>
                               
                            </div>
                        </div>
                    </main>
                </section>
            </main>
            <Footer />
        </>
    )
}