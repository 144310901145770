import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import imgerror from "../../views/assets/img/img404.png";
import resetpass from "../../views/assets/img/resetpass.png";
import Footer from "../admin/footer";
import { afterEmail, newPass } from "../../redux/actions/emailActions";
import { Link } from "react-router-dom";

export default function ResetPassword({
  match: {
    params: { token },
  },
}) {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.emailReducers);
  const { t } = useTranslation("common");
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    token,
  });

  useEffect(() => {
    if (!email.loaded) dispatch(afterEmail(token));
  }, [dispatch, email.loaded, token]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    dispatch(newPass(data));
  };
  return (
    <>
      {email.data > 0 || email.data.user === 1 ? (
        <div className="flex p-6 bg-black dark:bg-gray-900 pt-6 pb-24 xl:pt-32 xl:pb-60">
          <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
            <div className="flex flex-col overflow-y-auto md:flex-row">
              <div className="">
                <img className="object-cover sm:h-96" src={resetpass} alt="" />
              </div>
              <div className="flex items-center p-4 sm:p-12">
                <div className="w-full">
                  <h1 className="my-8 text-xl font-semibold text-gray-700">
                    {t("menu.new_pass")}
                  </h1>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="**********"
                      variant="outlined"
                      onChange={handleChange}
                      minLength={6}
                      required
                      className="block w-full mt-1 border-2 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent"
                      // helperText={handleMessage('password')}
                    />

                    <button
                      className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple"
                      type="submit"
                    >
                      {t("menu.recoveryPassword")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {load === true && !email.data.user && (
              <div
                className="text-right mr-24 mb-5"
                style={{ marginTop: "-4%", marginRight: "22%" }}
              >
                <i className="fa fa-spin fa-spinner fa-2x mr-3"></i>
              </div>
            )}
            {email.data.user > 0 && (
              <div className="text-xs mt-6 text-center bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline flex-shrink-0 mr-3 w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>{t("modal.newPass")}</span>
                </div>
                <div>
                  <span>
                    {t("register.login")}
                    <Link to="/login" className="text-blue-500">
                      {t("modal.here")}
                    </Link>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex p-6 bg-black dark:bg-gray-900 pt-10 pb-24 xl:pt-12 xl:pb-40">
          <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
            <div className="flex flex-col justify-center overflow-y-auto md:flex-row">
              <div className="flex items-center">
                <div className="flex w-full px-10 py-20">
                  <div className="px-10 w-2/3 z-10">
                    <img
                      className="object-cover sm:h-96 h-auto w-auto"
                      src={imgerror}
                      alt=""
                    />
                  </div>
                  <div className="grid justify-items-center w-1/3 z-0">
                    <h1 className="text-7xl text-center font-semibold text-gray-700 mt-10">
                      {t("menu.404")}
                    </h1>
                    <h2 className="pt-8 mb-12 border-t-2 border-gray-600 mt-4 text-center">
                      {t("menu.error_token")}
                    </h2>
                    <Link
                      to="/"
                      className="bg-gradient-to-r content-center from-gray-800 to-blue-500 hover:from-blue-500 hover:to-blue-500 text-sm text-white font-semibold px-6 pt-5 rounded-md"
                    >
                      {t("menu.back_home")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
