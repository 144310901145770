import React from "react";
import { useTranslation } from "react-i18next";
import imgAboutUs from "../../views/assets/img/campaign-creators-gMsnXqILjp4-unsplash.jpg";
import Footer from "../admin/footer";

export default function AboutUs() {
  // const [showmjs, setshowmjs] = useState(true)
  // const [showboton, setshowboton] = useState(false)
  const { t } = useTranslation("common");
  // const mostrarmjs = () => {
  //     setshowmjs(true);
  //     setshowboton(false);
  // };
  //  const nomostrar = () => {
  //     setshowmjs(false);
  //     setshowboton(true);
  //  };

  return (
    <>
      <div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:flex-row">
          <div className="mb-5 w-full lg:mb-0 lg:mr-10">
            <h2 className="relative mb-4 mt-2 text-left font-poppins lg:text-6xl sm:text-5xl md:text-6xl  font-bold tracking-tight text-gray-900 leading-normal">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="6bfa0e57-faa2-4bb2-ac0e-310782e5eb2d"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#6bfa0e57-faa2-4bb2-ac0e-310782e5eb2d)"
                    width="52"
                    height="24"
                  ></rect>
                </svg>
                <span className="font-poppins relative">
                  {t("aboutus.title")}
                </span>
              </span>
            </h2>
          </div>
          <div className="flex-grow pt-1">
            <div className="flex items-center mb-3">
              <span className="font-bold font-poppins tracking-wide sm:text-xl text-gray-900 text">
                {t("aboutus.NewsTitle")}
              </span>
              <span className="ml-1">
                <svg
                  className="w-5 h-5 mt-px text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  ></polygon>
                </svg>
              </span>
            </div>
            <p className="mx-2 font-poppins text-justify text-md font-light">
              {t("aboutus.ReadMore")}
            </p>
            {/* <a
              className="font-poppins bg-gradient-to-r from-gray-700 to-blue-600 hover:from-blue-600 hover:to-blue-600 text-white font-semibold inline-flex items-center text-xs lg:text-base py-2 px-10 mt-8 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              href="https://strut.company/"
              target="_blank"
              rel="noreferrer"
            >
              {t("aboutus.knowMore")}
            </a> */}
            
            {/* Read more */}
            {/* <div className="mx-2 text-justify text-md font-light w-full">
                                {t('aboutus.NewsHistory')}
                            </div>
                            { showmjs ? (
                                <div>
                                    <p className="mx-2 text-justify text-md font-light">
                                        {t('aboutus.ReadMore')}
                                    </p>
                                    <button 
                                        className="mt-4 mb-10 ml-2 mr-1 my-4 inline-flex items-center font-semibold transition-colors duration-200 text-red-600 hover:text-red-800" 
                                        onClick ={nomostrar}
                                    >
                                        {t('aboutus.HiddenNew')}
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                                        </svg>
                                    </button>
                                   
                                </div>
                             ):( <label ></label> )}
                            
                        {  showboton ? (
                            <button 
                                className="mt-4 mb-10 ml-2 mr-32 my-4 inline-flex items-center font-semibold transition-colors duration-200 text-red-600 hover:text-red-800" 
                                onClick ={mostrarmjs}
                                >
                                {t('aboutus.NewsButtom')}
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button> 
                        ):( <label htmlFor=""></label> )} */}
          </div>
        </div>
        <div className="relative">
          <img
            className="object-cover w-full h-56 sm:h-96"
            src={imgAboutUs}
            alt=""
          />
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
        </div>
      </div>

      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20 lg:pb-40">
        <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col justify-between overflow-hidden text-left bg-gray-800 rounded shadow-xl group hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <div className="p-5">
              <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full text-white bg-blue-600">
                <i className="fas fa-database"></i>
              </div>
              <p className="mb-2 font-bold font-poppins text-white">
                {t("aboutus.DataServiceTitle")}
              </p>
              <p className="text-sm leading-5 font-poppins text-white">
                {t("aboutus.DataService")}
              </p>
            </div>
            <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100"></div>
          </div>
          <div className="flex flex-col justify-between overflow-hidden text-left bg-gray-800 rounded shadow-xl group hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <div className="p-5">
              <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full text-white bg-blue-600">
                <i className="fas fa-bug"></i>
              </div>
              <p className="mb-2 font-bold font-poppins text-white">
                {t("aboutus.QASupportTitle")}
              </p>
              <p className="text-sm leading-5 font-poppins text-white">
                {t("aboutus.QASupport")}
              </p>
            </div>
            <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100"></div>
          </div>
          <div className="flex flex-col justify-between overflow-hidden text-left bg-gray-800 rounded shadow-xl group hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <div className="p-5">
              <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full text-white bg-blue-600">
                <i className="fas fa-mobile-alt"></i>
              </div>
              <p className="mb-2 font-bold font-poppins text-white">
                {t("aboutus.CustomWebTitle")}
              </p>
              <p className="text-sm leading-5 font-poppins text-white">
                {t("aboutus.CustomWeb")}
              </p>
            </div>
            <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100"></div>
          </div>
          <div className="flex flex-col justify-between overflow-hidden text-left bg-gray-800 rounded shadow-xl group hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <div className="p-5">
              <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full text-white bg-blue-600">
                <i className="fas fa-users"></i>
              </div>
              <p className="mb-2 font-bold font-poppins text-white">
                {t("aboutus.StafAugmentationTitle")}
              </p>
              <p className="text-sm leading-5 font-poppins text-white">
                {t("aboutus.StafAugmentation")}
              </p>
            </div>
            <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
