import React, { useState, useEffect } from "react";
import { loginAction } from "../../redux/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg_working from "../../views/assets/img/bg_working.jpg";
import Footer from "../admin/footer";
import Register from "./Register";
export default function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const dispatch = useDispatch();
  const main = useSelector((state) => state.mainReducers);
  const { t } = useTranslation("common");
  const [register, setRegister] = useState(false);
  const [effect, setEffect] = useState("fadeIn");
  const moveRegister = () => {
    setEffect("fadeOutLeft");
    setTimeout(() => {
      setRegister(true);
    }, 300);
  };

  useEffect(() => {
    dispatch({ type: "USER_ERROR_OFF" });
  }, [dispatch]);

  const handleChange = (text) => {
    setData({
      ...data,
      [text.target.name]: text.target.value,
    });
  };

  const handleSubmit = (e) => {
    dispatch({ type: "USER_BUTTON" });
    e.preventDefault();

    if (!data.email) {
      setError({ ...error, email: true });
      return;
    }
    if (!data.password) {
      setError({ ...error, password: true });
      return;
    }

    dispatch(loginAction(data));
  };
  const loginerror = useSelector((state) => state.userReducers);
  // console.log(loginerror)
  return (
    <>
      {main.status && <Redirect to="/profile" />}
      {!register && (
        <main className="">
          <div
            className={`flex items-center px-6 pb-8 pt-4 xl:py-32 bg-gray-100 animate__animated animate_fadeIn ${effect}`}
          >
            <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
              <div className="flex flex-col overflow-y-auto md:flex-row">
                <div className="h-32 md:h-auto md:w-1/2">
                  <img
                    aria-hidden="true"
                    className="object-cover w-full h-full dark:hidden"
                    src={bg_working}
                    alt="Office"
                  />
                </div>
                <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                  <div className="w-full">
                    <form action="" className="w-full" onSubmit={handleSubmit}>
                      <div className="block font-poppins mt-4 text-sm">
                        <h1 className="mb-10 text-xl font-semibold font-poppins text-gray-700 dark:text-gray-200">
                          <span className="block xl:inline">
                            {t("register.login")}
                          </span>
                        </h1>
                        <span className="text-gray-700 font-poppins dark:text-gray-400">
                          Email
                        </span>
                        <input
                          className="block font-poppins w-full mt-1 border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                          placeholder="tu@mail.com"
                          id="email"
                          name="email"
                          label={t("personal.email")}
                          variant="outlined"
                          onChange={handleChange}
                        />
                        {error.email && (
                          <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                            <svg
                              className="inline flex-shrink-0 mr-3 w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span>{t("personal.error.name_required")}</span>
                          </div>
                        )}
                      </div>
                      <div className="block font-poppins mt-4 text-sm">
                        <span className="text-gray-700 font-poppins dark:text-gray-400">
                          Password
                        </span>
                        <input
                          className="block w-full font-poppins mt-1 border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                          placeholder="***************"
                          type="password"
                          id="password"
                          name="password"
                          label={t("personal.password")}
                          variant="outlined"
                          onChange={handleChange}
                        />
                        {error.password && (
                          <div className="text-xs mt-1 bg-red-100 border-l-4 border-red-500 text-red-700 p-1">
                            <svg
                              className="inline flex-shrink-0 mr-3 w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span>{t("personal.error.password_required")}</span>
                          </div>
                        )}
                      </div>
                      {loginerror.loginerror.errors ? (
                        <div className="font-poppins block mt-4 text-sm">
                          <span className="text-red-700 font-poppins dark:text-gray-400">
                            {t("personal.error.login")}
                          </span>
                        </div>
                      ) : (
                        <label></label>
                      )}
                      <div className="flex justify-center  mt-10">
                        {/* 
                                       <Button
                                          status={user.button.status}
                                          text={t('menu.login')}
                                          icon="fa fa-unlock"
                                          className ="bg-red-600"
                                       />
                                        */}

                        <button
                          className="block w-full font-poppins px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-red"
                          type="submit"
                        >
                          {t("menu.login")}
                        </button>
                      </div>
                      <hr className="my-8" />
                    </form>
                    <button
                      className="flex items-center justify-center font-poppins w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
                      onClick={moveRegister}
                    >
                      {t("personal.registerMe")}
                    </button>

                    {/*<button className="flex items-center justify-center w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-white text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray">
                                    <svg
                                       className="w-4 h-4 mr-2"
                                       aria-hidden="true"
                                       viewBox="0 0 24 24"
                                       fill="currentColor"
                                    >
                                    <path
                                       d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
                                    />
                                    </svg>
                                    Github
                                 </button>*/}
                    <p className="mt-4">
                      <a
                        className="font-poppins text-sm font-medium text-blue-600 dark:text-purple-400 hover:underline"
                        href="/Forgot"
                      >
                        {t("menu.forgotPass")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}

      {register && (
        <Register
          register={() => setRegister(false)}
          initialEffect="fadeInRight"
          finalEffect={() => setEffect("fadeInLeft")}
        />
      )}
      <Footer />
    </>
  );
}
