import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//react table
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

// components
import Pagination from "../common/Pagination";
import InputSearch from "../common/InputSearch";
import TableGrid from "../common/TableGrid";
import Delete from "./Delete";
import FormEdit from "./FormEdit";

//actions
import { geteeditskill, getSkills } from "../../redux/actions/skillActions";

export default function Table() {
  const dispatch = useDispatch();
  let { url } = useRouteMatch();
  const { t } = useTranslation("common");

  const [showModal, setShowModal] = useState({
    uuid: "",
    delete: false,
    edit: false,
  });

  const [dataSkill, setDataSkill] = useState({});

  const handleModal = useCallback(
    (uuid) => {
      setShowModal({
        uuid,
        delete: true,
      });
      dispatch({ type: "SKILL_SUCCESS_OFF" });
    },
    [dispatch]
  );

  const handleModalSkills = useCallback(
    ({ uuid, experience_timefrom, experience_timeto, note }) => {
      setShowModal({
        uuid,
        edit: true,
      });
      setDataSkill({
        experience_timefrom,
        experience_timeto,
        note: note || "",
      });
      dispatch(geteeditskill(uuid));
      dispatch({ type: "SKILL_SUCCESS_OFF" });
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: () => t("skills.skill"),
        accessor: "skill.name",
      },
      {
        Header: () => t("skills.time"),
        accessor: "experience_time",
        Cell: ({ value }) => {
          const split = value.split(" ");
          return `${split[0]}  ${t("skills." + split[1])}`;
        },
      },
      {
        Header: () => t("skills.status_test"),
        accessor: "status_test",
        Cell: ({ value: status }) => (
          <span
            className={
              status > 0
                ? "text-green-700 bg-green-100 px-2 py-1 font-semibold leading-tight rounded-full "
                : "text-red-700 bg-red-100 px-2 py-1 font-semibold leading-tight  rounded-full "
            }
          >
            <label>{status > 0 ? t("skills.done") : t("skills.wait")}</label>
          </span>
        ),
      },
      {
        Header: () => t("references.test"),
        accessor: "skill_vacant",
        Cell: ({ row: { original } }) => (
          <div className="text-sm">
            {original.count_questions >= 10 &&
            original.status_test === 0 &&
            !original.skill_vacant &&
            original.skill.skill_test ? (
              <Link
                to={`${url}/test/${original.uuid}`}
                className="border-2 border-blue-500 hover:bg-blue-500 hover:text-white text-blue-600 font-semibold py-1.5 px-2 lg:inline-block block m-2 lg:rounded-lg md:rounded-lg"
              >
                {t("skills.go_to_text")}

                {t("!")}
              </Link>
            ) : original.status_test === 0 && !original.skill.skill_test ? (
              <span className="border-2 border-gray-500 bg-gray-500 text-white font-semibold py-1.5 px-2 lg:inline-block block m-2 lg:rounded-lg md:rounded-lg">
                {t("skills.disabledTest")}
              </span>
            ) : (
              ""
            )}
            {original.status_test === 0 &&
            original.skill_vacant &&
            original.skill.skill_test ? (
              <Link
                to={`${url}/test/${original.uuid}`}
                className="border-2 border-yellow-500 hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold py-1.5 px-2 lg:inline-block block m-2 lg:rounded-lg md:rounded-lg"
              >
                {t("skills.requerid")}

                {t("!")}
              </Link>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        Header: () => t("skills.actions_test"),
        accessor: "uuid",
        Cell: ({ row: { original } }) => (
          <div className="text-sm">
            <button
              className="border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
              onClick={() => handleModal(original.uuid)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>

            <button
              className="border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg"
              onClick={() => handleModalSkills(original)}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
            </button>
          </div>
        ),
      },
    ],
    [t, url, handleModal, handleModalSkills]
  );
  const { records: skills, loaded: loading } = useSelector(
    (state) => state.skillReducers
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: skills,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const closeModal = () => {
    setShowModal({
      edit: false,
    });
    dispatch({ type: "SKILL_SUCCESS_OFF" });
  };

  const closeModalSkills = async () => {
    setShowModal({
      edit: false,
    });
    await dispatch(getSkills());
  };

  return (
    <>
      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={loading}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      <div className="w-full overflow-hidden rounded shadow-xs mt-5">
        <Delete
          uuid={showModal.uuid}
          status={showModal.delete}
          closeModal={closeModal}
        />
        {showModal.edit && dataSkill && (
          <FormEdit
            uuid={showModal.uuid}
            preloadedValues={dataSkill}
            status={showModal.edit}
            closeModal={closeModalSkills}
          />
        )}
      </div>
    </>
  );
}
