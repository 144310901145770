import api from "../../utils/api";

export const getSkills = () => async (dispatch) => {
  try {
    const response = await api.get("/user-skills");
    if (response.status === 200) {
      dispatch({
        type: "SKILL_RECORDS",
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: "SKILL_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const saveSkills = (data) => async (dispatch) => {
  dispatch({ type: "SKILL_BUTTON" });
  try {
    const response = await api.post("/user-skills", data);
    if (response.data.added === "0")
      //es porque no se puede agregar ese skill por estar bloqueado
      alert(
        "No se puede agregar el skill porque sobrepasó la cantidad de intentos en la prueba"
      );
    if (response.status === 200) {
      dispatch({
        type: "SKILL_SUCCESS",
      });
    }
  } catch (e) {
    dispatch({
      type: "SKILL_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const deleteSkills = (uuid) => async (dispatch) => {
  const response = await api.delete(`/user-skills/${uuid}`);
  if (response.status === 200) {
    dispatch({
      type: "SKILL_SUCCESS",
    });
  }
};

export const getSkillsData = () => async (dispatch) => {
  try {
    const response = await api.get("/skills");
    dispatch({
      type: "SKILL_DATA_RECORD",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getSkillsDataUser = () => async (dispatch) => {
  try {
    const response = await api.get("/getskillsuser");
    dispatch({
      type: "SKILL_DATA_RECORD_USER",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getselect = () => async (dispatch) => {
  try {
    const response = await api.get("/skills2");
    dispatch({
      type: "SKILL_DATA_RECORD",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getSkillsData2 = (uuid2) => async (dispatch) => {
  try {
    if (uuid2) {
      const response = await api.get(`/b/skills/${uuid2}`);
      dispatch({
        type: "SKILL_RECORDS2",
        payload: response.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const geteeditskill = (uuid2) => async (dispatch) => {
  try {
    const response = await api.get(`/geteeditskill/${uuid2}`);
    dispatch({
      type: "SKILL_GET_EDIT",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const expreget = (uuid2) => async (dispatch) => {
  try {
    const response = await api.get(`/expreget/${uuid2}`);
    dispatch({
      type: "SKILL_GET_EDIT",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const updateSkill = (data, uuid) => async (dispatch) => {
  try {
    await api.put(`/editSkill/${uuid}`, data);
  } catch (e) {
    dispatch({
      type: "SKILL_ERROR",
      payload: e.response.data.errors,
    });
  }
};
