import api from '../../utils/api';

export const getSteps = () =>  async (dispatch) => {
	try {
		const response = await api.get("/steps/en");
		dispatch({
			type: 'STEP_RECRUITMENT',
			payload: response.data
		})
	} catch(e) {
		console.log(e)
	}
}