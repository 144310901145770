import React from "react";
import { useDispatch } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

export default function SingleError(props) {
  const dispatch = useDispatch();
  let { status, styleParent, errors, closer } = props;

  return (
    <>
      {status && (
        <div className={` ${styleParent} bg-red-50 border border-red-300`}>
          <Collapse in={status}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    if (typeof closer === "function") closer();
                    dispatch({ type: closer });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>Notice</AlertTitle>
              <ul>
                <li className="text-red-800">
                  <i className="fa fa-uncheck font-extralight"></i> {errors}
                </li>
              </ul>
            </Alert>
          </Collapse>
        </div>
      )}
    </>
  );
}
