import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListQuestion } from "../../../redux/actions/admin/questionActions";
import Pagination from "rc-pagination";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";

export default function ListQuestion(props) {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.questionReducers);
  const [filterRecords, setFilterRecords] = useState(undefined);
  const { t, i18n } = useTranslation("common");
  const [data, setData] = useState({
    current: 1,
    init: 0,
    final: 4,
  });
  const [limit, setLimit] = useState({ init: 5 });

  const handlePage = useCallback(
    (page) => {
      setData({
        current: page,
        final: page * limit.init - 1,
        init: page * limit.init - limit.init,
      });
    },
    [limit.init]
  );

  useEffect(() => {
    dispatch(getListQuestion(i18n.language));
  }, [dispatch, i18n.language]);

  useEffect(() => {
    if (limit.init > 0) {
      handlePage(1);
    }
  }, [dispatch, limit, handlePage]);

  useEffect(() => {
    var filter = list.questions_list.filter(
      (item, key) => key >= data.init && key <= data.final
    );
    setFilterRecords(filter);
  }, [dispatch, list, data]);

  return (
    <div className="my-10">
      <table className="w-10/12 mx-auto my-3">
        <thead>
          <tr>
            <th className="border border-gray-400 bg-gray-100 p-2">Question</th>
            <th className="border border-gray-400 bg-gray-100 p-2">Skills</th>
            <th className="border border-gray-400 bg-gray-100 p-2">Answers</th>
          </tr>
        </thead>
        <tbody>
          {filterRecords !== undefined &&
            filterRecords.length > 0 &&
            Object.entries(filterRecords).map(([key, item]) => (
              <tr key={key}>
                <td className="border p-2">
                  <div className="flex justify-start">
                    <div className="w-8 shadow-2xl mx-2 mr-2">
                      {item.image && (
                        <a
                          href={
                            `${process.env.REACT_APP_BASE_URL}` + item.image
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={
                              `${process.env.REACT_APP_BASE_URL}` + item.image
                            }
                            width="50"
                            height="50"
                            alt="question"
                          />
                        </a>
                      )}
                    </div>
                    <div className="w-auto">{item.question}</div>
                  </div>
                </td>
                <td className="border p-2">
                  {item.question_skills && item.question_skills.length > 0 && (
                    <>
                      {Object.entries(item.question_skills).map(
                        ([key2, item2]) => (
                          <div key={key2}>
                            {item2.skill.name}
                            <br />
                          </div>
                        )
                      )}
                    </>
                  )}
                </td>
                <td className="border p-2">
                  {item.answer && item.answer.length > 0 && (
                    <>
                      {Object.entries(item.answer).map(([key2, item2]) => (
                        <div key={key2}>
                          <div className="flex justify-start h-auto">
                            <div className="w-8 shadow-2xl mx-2 mr-2">
                              {item2.image && (
                                <a
                                  href={
                                    `${process.env.REACT_APP_BASE_URL}` +
                                    item2.image
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={
                                      `${process.env.REACT_APP_BASE_URL}` +
                                      item2.image
                                    }
                                    width="50"
                                    height="50"
                                    alt="answer"
                                  />
                                </a>
                              )}
                            </div>
                            <div className="w-auto">
                              {item2.answer}
                              {item2.result ? (
                                <i className="fa fa-check text-green-500 ml-1"></i>
                              ) : (
                                ""
                              )}
                              <br />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </td>
              </tr>
            ))}
          {filterRecords === undefined ||
            (filterRecords.length === 0 && (
              <tr>
                <td colSpan="4" className="text-center">
                  <i className="fa fa-spin fa-spinner fa-lg"></i>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="hidden w-10/12 mx-auto mb-1 sm:flex-1 sm:flex sm:items-center sm:justify-between sm:border-t-2 sm:border-b-2">
        <p className="text-sm text-gray-700">
          {t("pags.show")}
          <span className="font-medium ml-1 mr-1">{Number(data.init) + 1}</span>
          {t("pags.to")}
          <span className="font-medium ml-1 mr-1">
            {Number(data.final) + 1}
          </span>
          {t("pags.of")}
          <span className="font-medium ml-1 mr-1">
            {list.questions_list.length}
          </span>
          {t("pags.results")}
        </p>
        <div className="h-1 w-12 -mt-6 text-sm">
          <Select
            native
            onChange={(event) => {
              setLimit({
                ...limit,
                init: event.target.value,
              });
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
          </Select>
        </div>
        <Pagination
          className="d-flex flex-row align-items-center"
          current={data.current}
          total={list.questions_list.length}
          pageSize={limit.init}
          onChange={handlePage}
          locale={"es_ES"}
        />
      </div>
    </div>
  );
}
