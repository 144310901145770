import React, { useState, useEffect, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  getUUIDQuestions,
  getAnswers,
  saveAnswer,
  testCompleted,
  getUUIDCount,
} from "../../redux/actions/admin/userTestActions";
import CustomButton from "../common/Button";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useStopwatch } from "react-timer-hook";
import Modalvacio from "../modals/Modalvacio";
export default function UserTest(props) {
  let { user_skill_uuid } = useParams();
  const dispatch = useDispatch();
  const utest = useSelector((state) => state.userTestReducers);
  const [data, setData] = useState();
  const { t, i18n } = useTranslation("common");
  const { hours, minutes, seconds, reset } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    return () => {
      dispatch({ type: "UT_RESTART" });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!utest.loaded)
      dispatch(getUUIDQuestions(user_skill_uuid, i18n.language));
  }, [dispatch, user_skill_uuid, utest.loaded, i18n.language]);

  useEffect(() => {
    dispatch(getUUIDCount(user_skill_uuid, i18n.language));
  }, [dispatch, i18n.language, user_skill_uuid]);

  useEffect(() => {
    // var audio = document.getElementById("audio")
    //audio.src='';
    console.log(utest.records.length === 10);
    if (
      utest.records.current !== -1 &&
      utest.records.questions.length === 10 &&
      utest.records.current <= 9
    ) {
      dispatch(
        getAnswers(
          utest.records.questions[utest.records.current],
          i18n.language
        )
      );
    }
  }, [dispatch, utest.records, i18n.language]);
  const countt = useSelector((state) => state.userTestReducers);

  useEffect(() => {
    if (utest.reset_timer) {
      reset();
      dispatch({ type: "UT_RESET_TIMER_OFF" });
      setData(undefined);
    }
  }, [utest.reset_timer, reset, dispatch]);

  //se habilita el boton

  const handleChange = (e) => {
    if (utest.records.real_current + 1 < 9) {
      //se crea un id siempre que estemos antes de la última pregunta
      let idboton = utest.records.real_current + 1;
      document.getElementById(idboton).disabled = false;
    }
    setData({
      indice: utest.records.real_current + 1,
      user_skill_uuid: user_skill_uuid,
      question_uuid: document.getElementById("question_uuid").value,
      [e.target.name]: e.target.value,
      time: `${hours}:${minutes}:${seconds}`,
      paste_answer: localStorage.getItem("paste"),
      copy_answer: localStorage.getItem("copyAnswer"),
      copy_question: localStorage.getItem("copyQuestion"),
    });
  };
  const handleCopyPaste = (e) => {
    if (e.type === "paste") {
      localStorage.setItem("paste", 1);
    } else if (e.type === "copy") {
      localStorage.setItem("copyAnswer", 1);
    }
  };
  const handleCopyQuestion = (e) => {
    if (e.type === "copy") {
      localStorage.setItem("copyQuestion", 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data) {
      dispatch(saveAnswer(data));
      localStorage.removeItem("paste");
      localStorage.removeItem("copy");
      localStorage.removeItem("copyQuestion");
    }
  };

  const handleTestComplete = (e) => {
    if (data) {
      dispatch(testCompleted(data, user_skill_uuid));
      localStorage.removeItem("paste");
      localStorage.removeItem("copy");
      localStorage.removeItem("copyQuestion");
    }
  };

  return (
    <>
      {utest.records.questions.length < 10 ? (
        <div className="w-3/5 mx-auto mt-10 bg-green-50 flex justify-center flex-col items-center p-10 shadow border border-green-200">
          <div className="font-bold mb-5">{t("skills.noQuestion")}</div>
        </div>
      ) : (
        countt.countt &&
        (!utest.records.finish ? (
          utest.records.current === -1 ? (
            <div className="md:w-3/5 mx-auto mt-10 bg-yellow-50 flex justify-center flex-col items-center p-10 shadow border border-yellow-200">
              <div className="font-bold mb-5">{t("skills.welcome")}</div>
              <button
                className="block bg-yellow-300 border border-yellow-500 px-10 py-3 font-bold rounded shadow hover:bg-yellow-400 text-yellow-800"
                onClick={() => dispatch({ type: "UT_NEXT_QUESTION" })}
              >
                <i className="fa fa-flag"></i> {t("skills.startbtn")}
              </button>
            </div>
          ) : utest.loaded_answers ? (
            Object.values(utest.answers).length > 0 && (
              <form className="md:w-10/12 mx-auto" onSubmit={handleSubmit}>
                <Fragment>
                  <div className="bg-blue-50 border border-blue-400 p-4 text-blue-700">
                    <div className="flex justify-between">
                      <span onCopy={handleCopyQuestion}>
                        {utest.answers.question.question}{" "}
                      </span>
                      <span className="font-bold">
                        {hours}:{minutes}:{seconds}
                      </span>
                    </div>
                    <input
                      type="hidden"
                      id="question_uuid"
                      value={utest.answers.question.uuid}
                    />

                    {utest.answers.question.image &&
                      (utest.answers.extension === ".mp3" ? (
                        <>
                          <div>
                            <audio
                              src={
                                `${process.env.REACT_APP_BASE_URL}` +
                                utest.answers.question.image
                              }
                              id="audio"
                              preload="none"
                              className="flex justify-between bg-blue-50 border border-green-600 p-4 text-blue-700"
                              controls="controls"
                              controlsList="nodownload"
                            >
                              {" "}
                            </audio>
                          </div>
                        </>
                      ) : utest.answers.extension !== "" ? (
                        <>
                          <div>
                            <Modalvacio
                              urlimg={
                                `${process.env.REACT_APP_BASE_URL}` +
                                utest.answers.question.image
                              }
                            ></Modalvacio>
                          </div>
                        </>
                      ) : (
                        ""
                      ))}
                  </div>

                  {utest.answers.question.type === "select" && (
                    <div className="bg-gray-50 mt-2 border border-gray-300 p-4">
                      <FormControl component="fieldset" className="w-full p-5">
                        <RadioGroup
                          aria-label="coding"
                          name="answer_uuid"
                          id="answer_uuid"
                          onCopy={handleCopyPaste}
                          onChange={handleChange}
                        >
                          {Object.entries(utest.answers.question.answer).map(
                            ([key, xItem]) => (
                              <FormControlLabel
                                className="border-b w-full border-gray-100"
                                value={xItem.uuid}
                                control={<Radio />}
                                label={xItem.answer}
                                key={key}
                              />
                            )
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                  {utest.answers.question.type === "text" && (
                    <div className="bg-white mt-2 border border-gray-300 p-4">
                      <TextField
                        label="Answer"
                        multiline
                        rows={20}
                        variant="outlined"
                        name="annotation"
                        onPaste={handleCopyPaste}
                        onCopy={handleCopyPaste}
                        onChange={handleChange}
                        className="w-full"
                      />
                      <input type="hidden" id="paste_answer" value={0} />
                    </div>
                  )}
                </Fragment>
                <div className="mt-5 flex justify-end">
                  {utest.records.last_button === true &&
                  utest.records.real_current + 1 === 9 ? (
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      disableElevation
                      onClick={handleTestComplete}
                    >
                      <i className="fa fa-flag mr-1"></i> {t("skills.done")}
                    </Button>
                  ) : (
                    <CustomButton
                      status={utest.button}
                      text={t("skills.nextbtn")}
                      icon="fa fa-arrow-right"
                      disabledbutton={true}
                      id={utest.records.real_current + 1}
                    />
                  )}
                </div>
              </form>
            )
          ) : (
            <div className="flex justify-center mt-20">
              <i className="fa fa-spin fa-spinner fa-2x"></i>
            </div>
          )
        ) : (
          <div className="w-3/5 mx-auto mt-10 bg-green-50 flex justify-center flex-col items-center p-10 shadow border border-green-200">
            <div className="font-bold mb-5">{t("skills.thanks")}</div>
            <Link
              to="/profile/skills"
              className="block bg-green-300 border border-green-500 px-10 py-3 font-bold rounded shadow hover:bg-green-400 text-green-800"
            >
              <i className="fa fa-flag"></i> {t("skills.goskillbtn")}
            </Link>
          </div>
        ))
      )}
    </>
  );
}
