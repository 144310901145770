import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
// libs
import "./css/index.css";
import 'rc-pagination/assets/index.css';
import "./css/custom.css";
import "./css/fontawesome-free-5.15.2-web/css/all.min.css";
import "animate.css";
// components
import store from "./redux/store";
import App from "./App";
// Languages
import common_en from "./lang/en/common.json";
import common_es from "./lang/es/common.json";

// setLang();

i18next.init({
   interpolation: { escapeValue: false }, // React already does escaping
   lng: 'en', // language to use
   resources: {
      en: {
         common: common_en, // 'common' is our custom namespace
      },
      es: {
         common: common_es,
      },
   },
});

ReactDOM.render(
   <Provider store={store}>
      <I18nextProvider i18n={i18next}>
         <App />
      </I18nextProvider>
   </Provider>,
   document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// "php.executablePath": "C:\\xampp\\php\\php.exe",
// "php.validate.executablePath": "C:\\xampp\\php\\php.exe",