import api from "../../../utils/api";

export const getQuestions = (lang) => async (dispatch) => {
  try {
    const response = await api.get(`/questions/list/${lang}`);
    dispatch({
      type: "QUESTION_RECORDS",
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getQuestionByUuid = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`/questions/item/${uuid}`);
    dispatch({
      type: "QUESTION_RECORD",
      payload: response.data[0],
    });
  } catch (e) {
    console.log(e);
  }
};

export const saveQuestion = (data) => async (dispatch) => {
  dispatch({ type: "QUESTION_BUTTON" });
  try {
    const response = await api.post("/questions", data);
    dispatch({
      type: "QUESTION_STORE",
      payload: response.data[0],
    });
  } catch (e) {
    dispatch({
      type: "QUESTION_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const updateQuestion = (data, uuid) => async (dispatch) => {
  try {
    const response = await api.post(`/questions/update/${uuid}`, data);
    dispatch({
      type: "QUESTION_UPDATE",
      payload: response.data[0],
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateQuestionSkills = (skills, uuid) => async (dispatch) => {
  try {
    const response = await api.post(`/questions/update-skills/${uuid}`, skills);
    dispatch({
      type: "QUESTION_UPDATE",
      payload: response.data[0],
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteQuestion = (uuid) => async (dispatch) => {
  dispatch({ type: "QUESTION_BUTTON" });
  try {
    const response = await api.delete(`/questions/${uuid}`);
    if (response.status === 200) {
      dispatch({
        type: "QUESTION_DELETE",
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: "QUESTION_ERROR",
      payload: e.response.data.errors,
    });
  }
};

export const filterQuestions = (data) => async (dispatch) => {
  try {
    const response = await api.post(`/questions/filter`, data);
    dispatch({ type: "QUESTION_SEARCH", payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

export const restartState = () => async (dispatch) => {
  dispatch({ type: "QUESTION_RESTART" });
};

export const getListQuestion = (language) => async (dispatch) => {
  try {
    const response = await api.get(`/list/questions/${language}`);
    dispatch({ type: "QUESTION_LIST", payload: response.data });
  } catch (e) {
    console.log(e);
  }
};
